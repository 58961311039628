<header
	[ngClass]="(currentRoute=='home' || currentRoute=='services' || currentRoute=='quotebot_section')?'home_header':''"
	id="navbar">

	<!-- <div class="g-translator hideMobile"><div id="google_translate_element_header"></div></div> -->


	<nav class="navbar navbar-expand-lg navbar-dark ">
		<a class="navbar-brand" href="">
			<img src="assets/images/HomepageLogo.png" tabindex="1">
		</a>
		<div class="nav_top d-md-none d-lg-none text-center"
			style="flex-basis: auto; flex-grow: 1;padding: 6px 48px 5px 0px !important;">
			<a href="tel:+18005466266" class="cell-no-header"
				style="color: #fff !important;font-weight: 700 !important;font-size: 11px !important;" tabindex="1">
				<i class="fas fa-phone-alt" aria-hidden="true"></i>
				+1-800-5466.266
			</a>
			<!-- <div class="g-translator d-flex justify-content-center"><div id="google_translate_element_home_desktop"></div></div> -->
		</div>
		<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample05"
			aria-controls="navbarsExample05" aria-expanded="false" aria-label="Toggle navigation">
			<span class="navbar-toggler-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30"
					height="30" focusable="false">
					<title>Menu</title>
					<path stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10"
						d="M4 7h22M4 15h22M4 23h22"></path>
				</svg></span>
			<span class="cancel"><i class="cross-btn"></i></span>
		</button>
		<div class="collapse navbar-collapse align-items-center" id="navbarsExample05">
			<div class="nav_top d-lg-block d-none text-center" style="flex-basis: auto; flex-grow: 1;">
				<a href="tel:+18005466266" class="cell-no-header"
					style="color: #fff !important;font-weight: 700 !important;" tabindex="1">
					<i class="fas fa-phone-alt" aria-hidden="true"></i>
					+1-800-5466.266
				</a>
				<!-- <div class="g-translator d-flex justify-content-center"><div id="google_translate_element_home_desktop"></div></div> -->
			</div>
			<!--  class="hideDesktop"></div> -->
			<ul class="navbar-nav ml-auto">
				<li class="nav-item"> <a class="nav-link" [routerLinkActive]="['active']" routerLink="/home"
						tabindex="1">Home </a>
				</li>
				<!-- Added Tutorials link here -->
				<li class="nav-item"> <a class="nav-link" [routerLinkActive]="['active']" routerLink="tutorials"
						tabindex="1">Tutorials</a>
				</li>
				<li class="nav-item"> <a class="nav-link" [routerLinkActive]="['active']" routerLink="about-us"
						tabindex="1">ABOUT
						US / HISTORY</a>
				</li>
				<li class="nav-item"> <a class="nav-link" [routerLinkActive]="['active']" routerLink="fleet"
						tabindex="1">FLEET</a>
				</li>
				<li class="nav-item"> <a class="nav-link" [routerLink]="['services']" tabindex="1">SERVICES</a>
				</li>
				<!-- <li class="nav-item"> <a class="nav-link" [routerLinkActive]="['active']" routerLink="safety"
						tabindex="1">Safety</a>
				</li> -->
			</ul>
			<div class="nav-item dropdown button-signin" *ngIf="!currentUser; else loggedInUser">
				<a class="nav-link dropdown-toggle" tabindex="1" href="#" id="dropdown05" data-toggle="dropdown"
					aria-haspopup="true" aria-expanded="false">
					LOGIN / CREATE
				</a>
				<div class="dropdown-menu" aria-labelledby="dropdown05">
					<a class="dropdown-item indv-btn" (click)="loginButtons('individual')" style="font-size: 21px;">
						Individual Account
					</a>
					<a class="dropdown-item ta-btn text-header-drop" (click)="loginButtons('travel_agent')"
						style="font-size: 21px;">
						Travel Agent Account
					</a>
					<!-- <a class="dropdown-item nav-disabled" style="color: #000 !important;font-size: 21px;"
						(click)="loginButtons('corporate')">
						Corporate
					</a> -->
					<!-- <hr> -->
					<a class="dropdown-item ta-btn driver-dropdown text-header-drop"
						style="box-shadow: 0 5px 10px -6px rgb(0 0 0);font-size: 21px;"
						(click)="loginButtons('driver')">
						Driver / Chauffeur / Fleet
					</a>
					<hr>
					<a class="dropdown-item ta-btn sub-admin-dropdown text-header-drop" style="font-size: 21px;"
						(click)="loginButtons('sub_admin')">
						Sub Admin
					</a>
					<!-- <a class="dropdown-item ta-btn sub-admin-dropdown text-header-drop" style="font-size: 21px;"
						(click)="loginButtons('subscriber')">
						Partner Login
					</a> -->
				</div>
			</div>
			<ng-template #loggedInUser>
				<ul class="navbar-nav">
					<li class="nav-item dropdown no-caret mr-2 dropdown-user">
						<a class="user-profile btn btn-icon btn-transparent-dark dropdown-toggle" tabindex="1"
							id="navbarDropdownUserImage" href="javascript:void(0);" role="button" data-toggle="dropdown"
							aria-haspopup="true" aria-expanded="false" *ngIf="(currentUser?.roleName != 'individual')">
							{{ currentUser?.roleName == 'driver' ? currentUser?.affiliate_company ?
							currentUser?.affiliate_company
							: currentUser?.FirstName + currentUser?.Lastname.charAt(0) : currentUser?.name
							?
							currentUser?.name : currentUser?.FirstName +
							currentUser?.LastName?.charAt(0)}}
						</a>
						<a class="user-profile btn btn-icon btn-transparent-dark dropdown-toggle" tabindex="1"
							id="navbarDropdownUserImage" href="javascript:void(0);" role="button" data-toggle="dropdown"
							aria-haspopup="true" aria-expanded="false" *ngIf="(currentUser?.roleName == 'individual')">
							{{ currentUser?.name ? currentUser?.name : currentUser?.FirstName ? currentUser?.FirstName
							+ currentUser?.LastName?.charAt(0) : 'Individual'}}
						</a>
						<div class="logInDropdown dropdown-menu dropdown-menu-right border-0 shadow animated--fade-in-up"
							aria-labelledby="navbarDropdownUserImage">
							<h6 class="dropdown-header d-flex align-items-center">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
									<path fill="#000" fill-rule="evenodd"
										d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10ZM5.99927 17C7.36758 15.1783 9.54609 14 11.9998 14C14.4535 14 16.6321 15.1783 18.0004 17C16.6321 18.8217 14.4535 20 11.9998 20C9.54609 20 7.36758 18.8217 5.99927 17Z"
										clip-rule="evenodd" />
								</svg>
								<div class="dropdown-user-details">
									<div class="dropdown-user-details-name"
										*ngIf="(currentUser?.roleName != 'individual')">
										{{currentUser?.roleName == 'driver' ? currentUser?.affiliate_company ?
										currentUser?.affiliate_company
										: currentUser?.FirstName + currentUser?.Lastname.charAt(0) : currentUser?.name ?
										currentUser?.name : currentUser?.FirstName +
										currentUser?.LastName.charAt(0)}}
									</div>
									<div class="dropdown-user-details-name"
										*ngIf="(currentUser?.roleName == 'individual')">
										{{ currentUser?.name ? currentUser?.name : currentUser?.FirstName ?
										currentUser?.FirstName
										+ currentUser?.LastName?.charAt(0) : 'Individual'}}
									</div>
								</div>
							</h6>
							<div class="dropdown-divider"></div>
							<div *ngIf="currentUser.roleName=='driver' && accountStatus; else adminRole"
								class="dropdown-item" (click)="dashboard('affiliate')">
								<div class="d-inline dropdown-item-icon gear-bg-black settingsvg">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
										fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
										stroke-linejoin="round" class="feather feather-settings">
										<circle cx="12" cy="12" r="3"></circle>
										<path
											d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z">
										</path>
									</svg>
								</div>{{ Value }}
							</div>
							<ng-template #adminRole>
								<div *ngIf="currentUser.roleName=='admin' || currentUser.roleName=='sub_admin';else otherRole"
									class="dropdown-item nav-item-bt-1" (click)="dashboard('admin')">
									<div class="d-inline dropdown-item-icon gear-bg-black settingsvg ">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
											viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
											stroke-linecap="round" stroke-linejoin="round"
											class="feather feather-settings">
											<circle cx="12" cy="12" r="3"></circle>
											<path
												d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z">
											</path>
										</svg>
									</div>Admin Dashboard
								</div>
							</ng-template>
							<ng-template #otherRole>
								<div *ngIf="currentUser?.is_profile_complete==true; else completeProfile"
									class="dropdown-item" (click)="dashboard(currentUser?.roleName)">
									<div class="d-inline dropdown-item-icon gear-bg-black settingsvg">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
											viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
											stroke-linecap="round" stroke-linejoin="round"
											class="feather feather-settings">
											<circle cx="12" cy="12" r="3"></circle>
											<path
												d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z">
											</path>
										</svg>
									</div>Manage Bookings
								</div>
							</ng-template>
							<ng-template #completeProfile>
								<div class="dropdown-item" (click)="redirectCompleteProfile(currentUser?.roleName)">
									<div class="d-inline dropdown-item-icon gear-bg-black settingsvg">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
											viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
											stroke-linecap="round" stroke-linejoin="round"
											class="feather feather-settings">
											<circle cx="12" cy="12" r="3"></circle>
											<path
												d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z">
											</path>
										</svg>
									</div>Complete profile
								</div>
							</ng-template>
							<div class="dropdown-item logout-background" (click)="logout()">
								<div class="d-inline dropdown-item-icon">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
										fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
										stroke-linejoin="round" class="feather feather-log-out">
										<path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
										<polyline points="16 17 21 12 16 7"></polyline>
										<line x1="21" y1="12" x2="9" y2="12"></line>
									</svg>
								</div>
								Logout
							</div>
						</div>
					</li>
				</ul>
			</ng-template>
		</div>
	</nav>
</header>