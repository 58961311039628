<ngx-spinner></ngx-spinner>

<!-- FILTERS AND SORT BUTTON SECTION -->
<section id="fixed-filters-sortby-button" class="fixed-bottom hideInMobile">
	<div id="fixed-section-container" class="container-fluid mb-2">
		<div id="fixed-section-box" class="d-flex justify-content-center align-items-center">
			<div id="filters-button" class="d-block d-lg-none">
				<button aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
					class="filter-btn btn-secondary dropdown-toggle pl-4 mb-3" (click)="openfilters = true"
					type="button">
					Filters
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
						class="bi bi-filter" viewBox="0 0 16 16">
						<path
							d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
					</svg>
				</button>
			</div>
			<div id="sort-button" class="mb-3">
				<button class="filter-btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
					data-toggle="dropdown" aria-controls="dropdownMenu" aria-label="Toggle Navigation"
					aria-expanded="false">
					<span class="mr-2"> Sort By <i class="bi bi-caret-down"></i></span>

				</button>
				<div class="dropdown-menu" aria-labelledby="dropdownMenuButton" id="dropdownMenu">
					<a class="dropdown-item" (click)="Sort.LowToHigh()"> Price: Low to High </a>
					<a class="dropdown-item" (click)="Sort.HighToLow()"> Price: High to Low </a>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- FILTERS AND SORT BUTTON SECTION ENDS -->


<!-- FILTERS AND VEHICLE LISTING SECTION -->
<section class="container-fluid pt-2 pb-5 mob-height" [ngClass]="openfilters ? 'position-fixed' : ''">
	<div id="page-instruction-text" class="mx-2 mt-4 mb-2 hideMobile">
		<h5>
			Filter By
		</h5>
	</div>

	<p class="clearfix"></p>
	<div class="row">
		<!-- FILTERS SECTION -->
		<section id=" filters-container test" class="col-lg-3 col-xl-2">
			<div class="navbar-expand-lg pro-Fil">
				<div class="collapse navbar-collapse" id="navbarSupportedContent" [ngClass]="openfilters ? 'show' : ''">
					<div class="close-sidebar">
						<span (click)="openfilters = false">
							<i class="bi bi-x-lg"></i>
						</span>
					</div>
					<aside class="sidebar w-100">
						<div id="page-instruction-text" class="mx-2 mt-4 mb-4 hideDesktop">
							<h5>
								Filter By
							</h5>
						</div>
						<div class="d-flex ml-2 mb-4">
							<button type="button" (click)="clearFilters(null)"
								class="custom-filled-button orange-button mr-4">
								Clear
							</button>
							<button type="button" (click)="getVehicleDetails(); openfilters=false"
								class="custom-filled-button green-button">
								Apply filter
							</button>

						</div>
						<div class="fil-itm" *ngIf="filters?.selections?.length > 0">
							<div class="btn-custom-btn btn btn-outline-dark" *ngFor="let obj of filters?.selections"
								(click)="clearFilters(obj)">
								<span class="close" aria-hidden="true">
									&times;
								</span>
								{{ obj['name'] }}
							</div>
						</div>
						<!-- THE FILTER LIST - ACCORDION -->
						<div class="filter-list">
							<div class="accordion" id="accordionExample">
								<!-- OUTER CARD -->
								<div class="card accordian_Card mb-3"
									*ngFor="let filter_text of FILTERS_ORDER; index as filter_text_index">
									<!-- FILTER TITLE -->
									<!-- [ngClass]="filter_text_index > 0 ? 'collapsed' : ''" -->

									<div class="card-header" [attr.id]="'card_' + filter_text_index">
										<h2 class="mb-0">
											<button type="button"
												class="btn-custom-btn btn btn-link btn-block text-left collapsed"
												[attr.aria-controls]="'filter_' + filter_text_index"
												[attr.data-target]="'#filter_' + filter_text_index" aria-expanded="true"
												data-toggle="collapse">
												{{ formatter(filter_text.dp) | titlecase }}
											</button>
										</h2>
									</div>
									<!-- FILTER TITLE ENDS -->

									<!-- FILTER COLLAPSIBLE CARD - uncollapsed for first card-->
									<div class="collapse" data-parent="#accordionExample"
										[attr.aria-labelledby]="'card_' + filter_text_index"
										[attr.id]="'filter_' + filter_text_index"
										[ngClass]="filter_text_index == 0 ? 'show' : ''">
										<!-- IF RP IS A STRING -->
										<div class="card-body pt-0" *ngIf="!isArray(filter_text.rp)">
											<!-- Search Field -->
											<!-- added condition to hide search for first accordian -->
											<div class="filter-field mb-3"
												*ngIf="filter_text.dp != 'vehicle-type-preferences' ">
												<div class="input-group mb-3 ">
													<div class="input-group-prepend bg-white">
														<div class="input-group-text">
															<i class="fa fa-search" style="color:grey;"></i>
														</div>
													</div>
													<input class="form-control filter-search-area"
														(keyup)="searchIn(filter_text.rp, $event.target.value)"
														placeholder="Search" />
													<!--  for {{ halfText(formatter(filter_text.dp)) | titlecase }} -->
												</div>

											</div>
											<!-- Search Field Ends -->

											<!-- FILTER CHECKLIST AND FILTER CONTENT AREA -->
											<div class="checkbox-block">
												<!-- INPUT AND LABEL -->
												<div class="custom-control custom-checkbox"
													[ngClass]="filters?.copy['amenities'][14]?.chargeable == 'yes' && filter_index == 14 ? 'border-bottom border-secondary' : ''"
													*ngFor="let filter of filters.copy[filter_text.rp]; index as filter_index">
													<input type="checkbox" class="custom-control-input"
														[attr.id]="filter_text.dp + '_' + '_' + filter_index"
														[attr.name]="filter_text.dp + '_' + '_' + filter_index"
														(change)="filterSelection($event.target.checked, filter_text.rp, filter)"
														[checked]="isFilterAlreadySelected(filter_text.rp, filter)" />
													<label class="custom-control-label"
														[attr.for]="filter_text.dp + '_' + '_' + filter_index">
														<span class="checkbox-label-text">{{
															filter.name
															| titlecase }}</span>
														<i class="bi bi-currency-dollar text-orange"
															*ngIf="filter.chargeable == 'yes'"></i>
													</label>
												</div>
												<!-- INPUT AND LABEL ENDS -->

												<!-- SHOW MORE BUTTON -->
												<div [attr.id]="filter_text.dp"
													*ngIf="filters.vars[filter_text.rp] && (!isArray(filter_text?.rp) && filters?.original[filter_text.rp]?.length > min_length)">
													<span class="btn-custom-btn btn show-More" style="color:#ff7800"
														[attr.id]="'showMore_outer_' + filter_text_index"
														(click)="filters.vars['showMore_outer_' + filter_text.rp + '_' + filter_text_index] ? showMore('showMore_outer_' + filter_text.rp + '_' + filter_text_index, filter_text.rp, false) : showMore('showMore_outer_' + filter_text.rp + '_' + filter_text_index, filter_text.rp)">
														{{ filters.vars['showMore_outer_' + filter_text.rp + '_' +
														filter_text_index] ?
														'Show Less' : 'Show More (+' + this.min_length + ')' }}
														<i class="bi font-weight-bolder" [ngClass]="filters.vars['showMore_outer_' + filter_text.rp + '_' +
																		filter_text_index] ? 'bi-chevron-up' : 'bi-chevron-down'"></i>
													</span>
												</div>
												<!-- SHOW MORE BUTTON ENDS -->
											</div>
											<!-- FILTER CHECKLIST AND FILTER CONTENT AREA ENDS -->
										</div>

										<!-- IF RP IS AN ARRAY -->
										<!-- INNER ACCORDION -->
										<div class="accordion pl-4" id="innerAccordionExample">
											<div class="card-body" *ngIf="isArray(filter_text.rp)">
												<!-- INNER CARD 1 -->
												<div class="card"
													*ngFor="let inner_filter_text of filter_text.rp; index as inner_filter_text_index">
													<!-- FILTER TITLE -->
													<div class="card-header"
														[attr.id]="'inner_card_' + inner_filter_text_index">
														<h2 class="mb-0">
															<button type="button"
																class="btn-custom-btn btn btn-link btn-block text-left"
																[ngClass]="inner_filter_text_index > 0 ? 'collapsed' : ''"
																[attr.aria-controls]="'inner_filter_' + filter_text.dp + '_' + inner_filter_text_index"
																[attr.data-target]="'#inner_filter_' + filter_text.dp + '_' + inner_filter_text_index"
																aria-expanded="true" data-toggle="collapse">
																{{ formatter(inner_filter_text.dp) | titlecase }}
															</button>
														</h2>
													</div>
													<!-- FILTER TITLE ENDS -->

													<!-- FILTER COLLAPSIBLE CARD - uncollapsed for first card-->
													<div class="collapse" data-parent="#innerAccordionExample"
														[attr.aria-labelledby]="'inner_card_' + inner_filter_text_index"
														[attr.id]="'inner_filter_' + filter_text.dp + '_' + inner_filter_text_index"
														[ngClass]="inner_filter_text_index == 0 ? 'show' : ''">
														<!-- IF RP HAS A STRING VALUE -->

														<div class="card-body pt-0"
															*ngIf="!isArray(inner_filter_text.rp)">
														</div>
														<!-- Search Field -->
														<div class="filter-field mb-3">
															<div class="input-group mb-3 ">
																<div class="input-group-prepend bg-white">
																	<div class="input-group-text">
																		<i class="fa fa-search" style="color:grey;"></i>
																	</div>
																</div>
																<input class="form-control filter-search-area"
																	(keyup)="searchIn(inner_filter_text.rp, $event.target.value)"
																	placeholder="Search" />
																<!--  for {{ halfText(formatter(inner_filter_text.dp)) | titlecase }} -->
															</div>
															<!-- Search Field Ends -->

															<!-- FILTER CHECKLIST AND FILTER CONTENT AREA -->
															<div class="checkbox-block">
																<!-- INPUT AND LABEL -->
																<div class="custom-control custom-checkbox"
																	*ngFor="let inner_filter of filters?.copy[inner_filter_text.rp]; index as inner_filter_index">
																	<input type="checkbox" class="custom-control-input"
																		[attr.id]="inner_filter_text.dp + '_' + '_' + inner_filter_index"
																		[attr.name]="inner_filter_text.dp + '_' + '_' + inner_filter_index"
																		(change)="filterSelection($event.target.checked, inner_filter_text.rp, inner_filter)"
																		[checked]="isFilterAlreadySelected(inner_filter_text.rp, inner_filter)" />
																	<label class="custom-control-label"
																		[attr.for]="inner_filter_text.dp + '_' + '_' + inner_filter_index">
																		<span *ngIf="inner_filter.name != 'LGBTQIA+'">
																			{{ formatter(inner_filter.name) | titlecase
																			}}
																		</span>
																		<span *ngIf="inner_filter.name == 'LGBTQIA+'">
																			<span class="checkbox-label-text">{{
																				inner_filter.name }}</span>
																		</span>
																	</label>
																</div>
																<!-- INPUT AND LABEL ENDS -->

																<!-- SHOW MORE BUTTON -->
																<div
																	*ngIf="filters.vars[inner_filter_text.rp] && (!isArray(inner_filter_text.rp) && filters?.original[inner_filter_text.rp]?.length > min_length)">
																	<span class="btn-custom-btn btn show-More"
																		style="color:#ff7800"
																		[attr.id]="'showMore_inner' + inner_filter_text_index"
																		(click)="filters.vars['showMore_inner_' + inner_filter_text.rp + '_' +
																		inner_filter_text_index] ? showMore('showMore_inner_' + inner_filter_text.rp + '_' + inner_filter_text_index, inner_filter_text.rp, false) : showMore('showMore_inner_' + inner_filter_text.rp + '_' + inner_filter_text_index, inner_filter_text.rp)">
																		{{ filters.vars['showMore_inner_' +
																		inner_filter_text.rp + '_' +
																		inner_filter_text_index] ? 'Show Less' :
																		'Show More (+' + this.min_length + ')' }}
																		<i class="bi font-weight-bolder" [ngClass]="filters.vars['showMore_inner_' + inner_filter_text.rp + '_' +
																		inner_filter_text_index] ? 'bi-chevron-up' : 'bi-chevron-down'"></i>
																	</span>
																</div>
																<!-- SHOW MORE BUTTON ENDS -->
															</div>
															<!-- FILTER CHECKLIST AND FILTER CONTENT AREA ENDS -->
														</div>
													</div>
												</div>
												<!-- INNER CARD 1 ENDS -->
											</div>
										</div>
										<!-- INNER ACCORDION ENDS -->
									</div>
								</div>
							</div>
						</div>
						<!-- FILTER LIST ENDS - ACCORDION-->
						<div class="d-flex ml-2 mb-4">
							<button type="button" (click)="clearFilters(null)"
								class="custom-filled-button orange-button mr-4">
								Clear
							</button>
							<button type="button" (click)="getVehicleDetails(); openfilters=false"
								class="custom-filled-button green-button">
								Apply filter
							</button>

						</div>
					</aside>
				</div>
			</div>
		</section>
		<!-- FILTERS SECTION ENDS -->



		<!-- MASTER VEHICLE TYPES SECTION -->
		<div class="hideInMobile col-md-12 col-lg-9 col-xl-10"
			[ngStyle]="openfilters ? {'z-index': -1, 'position': 'relative'} : ''"
			*ngIf="vehicleDetails.length == 0 || master_vehicles.length == 0">
			<!-- title box -->
			<div class="select_vehicle_div">
				<div class="row d-flex justify-content-between align-items-center">
					<div class="col-md-6">
						<h5 style="color:#fff;font-size: 22px;">
							Select the vehicle that meets your requirements.
						</h5>
						<span style="color:#fff;font-size: 14px;">
							<span style="color:#FEB573;">Use Filter / </span>Sort for more specific choices to match
							your
							search for rates.
						</span>
					</div>
					<div class="col-md-6 d-flex justify-content-end">
						<button type="button" class="custom-filled-button green-button" (click)="backButton()">
							<i class="bi bi-arrow-left mx-1"></i>
							Back to Home
						</button>
					</div>
				</div>
			</div>

			<!-- make model colours years div -->
			<!-- <div class="make-model-container">
				<div class="d-flex justify-content-between">
					<div class="d-flex align-items-center justify-content-end col-md-6">
						<button type="button" (click)="getVehicleDetails(); openfilters=false"
							class="custom-filled-button green-button mr-4">
							Apply filter
						</button>
						<button type="button" (click)="clearFilters(null)" class="custom-filled-button orange-button">
							Clear filter
						</button>
					</div>
				</div> -->
			<!-- THE SELECTED FILTERS LIST -->
			<!-- <div class="selected-filter-box fil-itm mt-2" *ngIf="filters?.selections?.length > 0">
					<div class="btn-custom-btn btn btn-outline-dark" *ngFor="let obj of filters?.selections"
						(click)="clearFilters(obj)">
						<span class="close" aria-hidden="true">
							&times;
						</span>
						{{ obj['name'] }}
					</div>
				</div>
			</div> -->

			<!-- vehicle listing below -->
			<p style="font-size: 1rem; font-weight: 500; color: #e63232"
				*ngIf="(vehicleDetails.length == 0 && this.filters.selections.length > 0) || master_vehicles.length == 0">
				{{ no_vehicle_msg }}
			</p>
			<div id="master-vehicle-box-container" class="vehicle-grid-card" *ngIf="master_vehicles.length > 0">
				<div id="master-vehicle-box" class="card mb-5 d-flex flex-nowrap flex-md-wrap cursor-pointer"
					*ngFor="let vehicle_obj of master_vehicles; index as vehicle_obj_index"
					(click)="selectCategory(vehicle_obj?.name)" style="position: relative !important;">
					<div id="vehicle-image" class="imageStyle bg-white d-flex justify-content-center">
						<img [src]="vehicle_obj.cat_img" class="img-fluid" [attr.id]="'vehicle_' + vehicle_obj_index" />
					</div>
					<div id="vehicle-information" class="m-0" style="padding:15px;">
						<p id="vehicle-name" class="vehicle-name-text">
							{{ vehicle_obj?.name }}
						</p>
						<p id="vehicle-price" class="font-weight-bolder mb-4" style="font-size: 1.1rem"
							*ngIf="('rate_breakdown_' + getKeyName()) != undefined || ('rate_breakdown_' + getKeyName()).length <= 0">
							<span style="color: #c94505;font-weight: 600;">
								From&nbsp;
							</span>
							{{currencySymbol}} {{ vehicle_obj['rate_breakdown_' + getKeyName()]?.grand_total !=
							undefined
							?
							vehicle_obj['rate_breakdown_' + getKeyName()]?.grand_total : '0.00' }}
						</p>
						<div id="icons" class="passnger-luggage-div">
							<div class="icon-container">
								<img alt="" src="assets/images/select_vehicle/pass.png"
									style="margin-right: 0.50rem;" />
								<span>Passengers</span>
								<span class="font-weight-bolder mx-2">{{ vehicle_obj?.passenger }}</span>
							</div>
							<div class="icon-container">
								<img alt="" src="assets/images/select_vehicle/lug.png" style="margin-right: 0.50rem;" />
								<span>Luggages</span>
								<span class="font-weight-bolder mx-2">{{ vehicle_obj?.luggage }}</span>
							</div>
						</div>
						<div class="d-flex justify-content-end mt-4"
							style="position: absolute; bottom: 0;right: 0;margin-bottom: 7px;">
							<button type="button" class="view-more-circle-button"
								(click)="selectCategory(vehicle_obj?.name)">
								View More
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- MASTER VEHICLE TYPES SECTION ENDS -->


		<!-- MASTER VEHICLE TYPES SECTION IN MOBILE-->
		<div class="hideInDesktop col-md-12 col-lg-9 col-xl-10"
			[ngStyle]="openfilters ? {'z-index': -1, 'position': 'relative'} : ''"
			*ngIf="vehicleDetails.length == 0 || master_vehicles.length == 0">
			<!-- title box -->
			<div class="select_vehicle_div_mobile">
				<button type="button" class="back_button_mobile mb-2" (click)="backButton()">
					<i class="bi bi-arrow-left mx-1"></i>
					Back to Home
				</button>
				<div class="row d-flex justify-content-between align-items-center">
					<div class="col-md-12" style="display: flex;flex-direction: column;">
						<span style="color:#fff;font-size: 16px;font-weight: 500;">
							Select the vehicle that meets your requirements
						</span>
						<span style="color:#fff;font-size: 13px;font-weight: 500;">
							Use <span style="color:#FEB573;font-weight: 500;">Filter / Sort</span> for more specific
							choices to match
							your
							search for rates.
						</span>
					</div>

				</div>
			</div>
			<div class="sort_filter_div_mobile mb-3">
				<div>
					<!-- <span style="font-weight: 500;margin-left: 10px;">
						Save this search
					</span> -->
				</div>
				<div class="d-flex justify-content-end ">
					<span>
						<a href="javascript:void(0);" role="button" data-toggle="dropdown" aria-haspopup="true"
							aria-expanded="false"
							style="text-decoration: none;font-weight: 500;color: blue;margin-right: 5px;">
							<i class="bi bi-caret-down"></i> Sort
						</a>
						<div class="dropdown-menu dropdown-menu-right border-0 shadow animated--fade-in-up"
							aria-labelledby="navbarDropdownUserImage">
							<a class="dropdown-item" (click)="Sort.LowToHigh()"> Price: Low to High </a>
							<a class="dropdown-item" (click)="Sort.HighToLow()"> Price: High to Low </a>
						</div>
					</span>
					<span>
						|
					</span>
					<span style="font-weight: 500;margin: 0px 10px 0px 5px;color: blue;" (click)="openfilters = true">
						<i class="bi bi-filter"></i> Filter
					</span>
				</div>
			</div>
			<p style="font-size: 1rem; font-weight: 500; color: #e63232"
				*ngIf="(vehicleDetails.length == 0 && this.filters.selections.length > 0) || master_vehicles.length == 0">
				{{ no_vehicle_msg }}
			</p>
			<div id="master-vehicle-box-container" class="vehicle-grid-card" *ngIf="master_vehicles.length > 0"
				style="grid-gap: 15px !important;">
				<div id="master-vehicle-box" class="card mb-1 d-flex flex-nowrap flex-md-wrap cursor-pointer"
					*ngFor="let vehicle_obj of master_vehicles; index as vehicle_obj_index"
					(click)="selectCategory(vehicle_obj?.name)" style="position: relative !important;">
					<div id="vehicle-image" class="bg-white d-flex">
						<div class="col-sm-3 imageStyle-mobile">
							<img [src]="vehicle_obj.cat_img" [attr.id]="'vehicle_' + vehicle_obj_index" />
						</div>
						<div class="col-sm-9" style="padding: 0 !important;">
							<div class="d-flex flex-column">
								<p id="vehicle-name" class="vehicle-name-text-mobile">
									{{ vehicle_obj?.name }}
								</p>
								<p id="vehicle-price" class="font-weight-bolder" style="font-size: 0.8rem;"
									*ngIf="('rate_breakdown_' + getKeyName()) != undefined || ('rate_breakdown_' + getKeyName()).length <= 0">
									<span style="color: #c94505;font-weight: 500;">
										From&nbsp;
									</span>
									{{currencySymbol}} {{ vehicle_obj['rate_breakdown_' + getKeyName()]?.grand_total !=
									undefined
									?
									vehicle_obj['rate_breakdown_' + getKeyName()]?.grand_total : '0.00' }}
									(All Inclusive Rate)
								</p>
								<div id="icons" class="d-flex mb-2" style="gap:8px;">
									<div class="icon-container-mobile">
										<img alt="" src="assets/images/select_vehicle/pass.png" style="padding: 3px;" />
										<span style="font-size: 0.8rem;margin-left: 0.1rem;font-weight: 500;">Pax</span>
										<span class="font-weight-bolder" style="font-size: 0.9rem;padding: 3px;">{{
											vehicle_obj?.passenger }}</span>
									</div>
									<div class="icon-container-mobile">
										<img alt="" src="assets/images/select_vehicle/lug.png" style="padding: 3px;" />
										<span
											style="font-size: 0.8rem;margin-left: 0.1rem;font-weight: 500;">Luggage</span>
										<span class="font-weight-bolder" style="font-size: 0.9rem;padding: 3px;">{{
											vehicle_obj?.luggage }}</span>
									</div>
								</div>
								<div class="d-flex justify-content-end mb-2">
									<button type="button" class="view-more-circle-button"
										(click)="selectCategory(vehicle_obj?.name)" style="padding: 2px 5px !important;font-size: 0.7rem !important;">
										View More
									</button>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
		<!-- MASTER VEHICLE TYPES SECTION IN MOBILE ENDS -->
	</div>
</section>









<!-- --------------------------------------- MODALS -------------------------------------- -->

<div aria-hidden="true" aria-labelledby="filtersModal" class="modal" id="filtersModal" tab-index="-1">
	<div class="modal-dialog modal-dialog-scrollable modal-lg ">
		<div class=" modal-content">
			<div class="modal-header d-block" style="font-family: 'pt_serifregular'">
				<h4 class="modal-title">
					{{ formatter(vehicle_selected?.selection_button) | titlecase }}
				</h4>
				<div class="modal-body">
					<div>
						<div *ngIf="vehicle_selected?.selection_button == 'amenities'">
							<table class="table">
								<thead>
									<tr>
										<th> Name </th>
										<th> Chargeable/Non-Chargeable </th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let amenity of vehicle_selected?.amenities">
										<td> <strong> {{ amenity.name }} </strong></td>
										<td> {{ amenity.chargeable == 'yes' ? 'Chargeable' : 'Non-Chargeable' }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div *ngIf="vehicle_selected?.selection_button == 'driver_info'">
						<div class="d-flex align-items-center"
							*ngIf="vehicle_selected?.driverInformation; else noinfotext">
							<div class="align-self-center justify-self-center">
								<img [src]="vehicle_selected?.driverInformation?.imageUrl" class="driver_image" />
							</div>
							<div id="driver-info" style="width: 100%" class="align-self-start">
								<div class="row mx-3 my-2" *ngFor="let item of modal_driver_info_labels">
									<div class="col-md-5">
										{{ formatter(item) | titlecase }}
									</div>
									<div class="col-md-auto">
										<strong *ngIf="item != 'starRating'">
											{{ vehicle_selected.driverInformation[item] != '' ?
											formatter(vehicle_selected.driverInformation[item]) : 'No' }}
										</strong>
										<strong *ngIf="item == 'starRating'">
											{{ vehicle_selected.driverInformation[item] != '' ?
											formatter(vehicle_selected.driverInformation[item]) : 'Not Rated' }}
										</strong>
									</div>
								</div>
							</div>
						</div>
						<ng-template #noinfotext>
							<p class="text-danger">
								No Information available
							</p>
						</ng-template>
					</div>
				</div>
				<div class="modal-footer">
					<div class="btn-Area">
						<button class="btn-custom-btn btn btn-secondary px-5" data-dismiss="modal" type="button">
							Close
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>



<div class="modal fade show" id="myModal" role="dialog">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<button type="button" class="close closeButton" data-dismiss="modal" aria-label="Close">
				<span aria-hidden="true">&times;</span>
			</button>
			<div class="modal-header pb-0">
				<h3 class="modal-title mb-0">Edit Passengers / Luggages</h3>
			</div>
			<hr>
			<div class="modal-body">
				<div class="row">
					<!-- Number of Passengers -->
					<div class="col-6 col-md-6">
						<label>
							# of Passengers
						</label>
						<div>
							<ul class="number passengers_qty">
								<li class="user_grp"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
										fill="#fff" height="22px">
										<path
											d="M15.71,12.71a6,6,0,1,0-7.42,0,10,10,0,0,0-6.22,8.18,1,1,0,0,0,2,.22,8,8,0,0,1,15.9,0,1,1,0,0,0,1,.89h.11a1,1,0,0,0,.88-1.1A10,10,0,0,0,15.71,12.71ZM12,12a4,4,0,1,1,4-4A4,4,0,0,1,12,12Z" />
									</svg>
								</li>
								<li>
									<div class="input-group">
										<div class="input-group-prepend">
											<button style="min-width: 2.5rem"
												class="btn-custom-btn btn btn-decrement btn-outline-secondary"
												type="button" (click)="change('d','p')">
												<strong>-</strong>
											</button>
										</div>
										<input type="number" [value]="quotebot_form.no_of_passenger" min="0" max="1000"
											step="1" inputmode="decimal" style="text-align: center" class="form-control"
											placeholder="">
										<div class="input-group-append">
											<button style="min-width: 2.5rem"
												class="btn-custom-btn btn btn-increment btn-outline-secondary"
												type="button" (click)="change('i','p')">
												<strong>+</strong>
											</button>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>

					<!-- Number of Luggage -->
					<div class="col-6 col-md-6">
						<label># of Luggage</label>
						<div>
							<ul class="number passengers_qty">
								<li class="user_grp"><svg xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd"
										stroke-linejoin="round" stroke-miterlimit="1.414" clip-rule="evenodd"
										viewBox="0 0 65 64" fill="#fff" height="22px">
										<rect width="64" height="64" x=".749" fill="none" />
										<path fill-rule="nonzero"
											d="M24.784,11.883l0,-2.92c0.01,-1.551 1.341,-2.959 2.954,-2.989c4.016,-0.026 8.032,-0.026 12.048,0c1.549,0.029 2.943,1.364 2.954,2.989l0,2.944c4.998,0.016 9.996,0.037 14.994,0.052c2.578,0.024 4.932,2.258 4.957,4.957c0.035,12.002 0.111,24.004 0,36.005c-0.04,2.551 -2.246,4.883 -4.926,4.925c-16.001,0.149 -32.005,0.149 -48.007,0c-2.561,-0.04 -4.883,-2.229 -4.925,-4.925c-0.112,-12.012 -0.112,-24.025 0,-36.036c0.04,-2.548 2.238,-4.884 4.925,-4.926c5.009,-0.046 10.017,-0.069 15.026,-0.076Zm32.938,2.071c-5.268,-0.016 -42.618,0 -47.9,0c-1.552,0.005 -2.979,1.335 -2.994,2.974c-0.037,11.989 -0.112,23.979 0,35.968c0.024,1.545 1.364,2.941 2.974,2.956c15.979,0.05 31.96,0.148 47.938,-0.001c1.545,-0.024 2.941,-1.393 2.956,-2.973c0.035,-11.99 0.111,-23.98 0,-35.968c-0.024,-1.545 -1.355,-2.941 -2.974,-2.956Zm-44.909,5.985l-0.997,0c-0.577,-0.037 -0.697,-0.209 -0.864,-0.498c-0.343,-0.594 0.102,-1.447 0.864,-1.497l43.892,0c0.044,0.003 0.087,0.006 0.13,0.009c0.419,0.083 0.516,0.192 0.662,0.382c0.438,0.571 0.043,1.55 -0.792,1.604l-40.9,0l0,4.988c-0.023,0.979 -1.96,1.461 -1.995,0l0,-4.988Zm27.932,-8.038c0,-0.987 0.018,-1.974 -0.001,-2.961c-0.016,-0.512 -0.467,-0.961 -0.984,-0.971c-3.993,-0.025 -7.987,0 -11.98,0c-0.525,0.003 -0.998,0.454 -1.001,1l0,2.912c4.655,-0.003 9.311,0.006 13.966,0.02Z" />
									</svg>
								</li>
								<li>
									<div class="input-group  ">
										<div class="input-group-prepend">
											<button style="min-width: 2.5rem"
												class="btn-custom-btn btn btn-decrement btn-outline-secondary"
												type="button" (click)="change('d','l')">
												<strong>-</strong>
											</button>
										</div>
										<input type="number" [value]="quotebot_form.no_of_luggage" min="0" max="2000"
											step="1" inputmode="decimal" style="text-align: center"
											class="form-control " placeholder="">
										<div class="input-group-append">
											<button style="min-width: 2.5rem"
												class="btn-custom-btn btn btn-increment btn-outline-secondary"
												type="button" (click)="change('i','l')">
												<strong>+</strong>
											</button>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer pt-0">
				<button class="custom-btn-custom-btn btn savebutton" data-dismiss="modal"
					(click)="fetchMasterVehicles()" tabindex="1">SAVE</button>
			</div>
		</div>
	</div>
</div>

<app-scroll-to-top></app-scroll-to-top>