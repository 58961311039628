<form [formGroup]="addVehicleForm" class="step_form_wrapper" appInvalidControlScroll>

	<!-- Main page content-->
	<section class="user_information">
		<h2 class="headinginfo"> Vehicle Settings <span>( Match Make and Model as close as possible )</span></h2>
		<div class="container mt-1 ">
			<div class="row ">
				<div class="col-md-6">
					<!-- <mat-form-field appearance="outline"
						[ngClass]="(errorMsg && f.vehicleType.errors) || (submittedForm && f.vehicleType.errors) ? 'mat-form-field-invalid ng-invalid' : ''">
						<mat-label>Vehicle Type <span class="asterisk">*</span></mat-label>
						<input type="text" id="vehicleTypeField" placeholder="Pick one" aria-label="Number" matInput
							(keyup)="searchVehicleType($event.target.value)" [matAutocomplete]="auto1" tabindex="1">
						<mat-autocomplete #auto1="matAutocomplete">
							<mat-option *ngFor="let vehicleType of filteredVehicleTypes" [value]="vehicleType.name"
								(onSelectionChange)="selectVehicleType(vehicleType.ID,$event.isUserInput)">
								{{vehicleType.name}}
							</mat-option>
						</mat-autocomplete>
					</mat-form-field> -->
					<ng-select appearance="outline" [items]="filteredVehicleTypes" dropdownPosition="bottom"
						[markFirst]="true" [virtualScroll]="true" bindLabel="name" bindValue="ID"
						placeholder="Vehicle Type" formControlName="vehicleType"
						(change)="handleChangeVehicleType($event)"
						[ngClass]="{ 'border-red': (errorMsg && f.vehicleType.errors) || (submittedForm && f.vehicleType.errors) || !isVehicleTypeSelected }">
					</ng-select>
					<div *ngIf="(errorMsg && f.vehicleType.errors) || (submittedForm && f.vehicleType.errors)"
						class="text-danger margin-bottom-thin my-mat-hint" style="margin-bottom: 9px;">
						<div *ngIf="f.vehicleType.errors.required">The Vehicle Type is required</div>
					</div>
				</div>
				<div class="col-md-6">
					<!-- <mat-form-field appearance="outline"
						[ngClass]="(errorMsg1 && f.make.errors) || (submittedForm && f.make.errors) ? 'mat-form-field-invalid ng-invalid' : ''">
						<mat-label>Make <span class="asterisk">*</span></mat-label>
						<input type="search" id="makeField" placeholder="Pick one" aria-label="Number" matInput
							(keyup)="searchMake($event.target.value)" [matAutocomplete]="auto2" tabindex="1">
						<mat-autocomplete #auto2="matAutocomplete">
							<mat-option *ngFor="let m of filteredMake" [value]="m.name"
								(onSelectionChange)="selectMake(m.ID,$event.isUserInput)">
								{{m.name}}
							</mat-option>
						</mat-autocomplete>
					</mat-form-field> -->
					<ng-select appearance="outline" [items]="filteredMake" dropdownPosition="bottom" [markFirst]="true"
						[virtualScroll]="true" bindLabel="name" bindValue="ID" placeholder="Make"
						formControlName="make">
					</ng-select>
					<div *ngIf="(errorMsg1 && f.make.errors) || (submittedForm && f.make.errors)"
						class="text-danger margin-bottom-thin my-mat-hint"
						style="margin-top: -20px; margin-bottom: 20px;">
						<div *ngIf="f.make.errors.required">The Make and Model is required</div>
					</div>
				</div>
				<div class="col-md-6">
					<!-- <mat-form-field appearance="outline"
						[ngClass]="(errorMsg2 && f.model.errors) || (submittedForm && f.model.errors) ? 'mat-form-field-invalid ng-invalid' : ''">
						<mat-label>Model <span class="asterisk">*</span></mat-label>
						<input type="search" id="modelField" placeholder="Pick one" aria-label="Number" matInput
							(keyup)="searchModel($event.target.value)" [matAutocomplete]="auto3" tabindex="1">
						<mat-autocomplete #auto3="matAutocomplete">
							<mat-option *ngFor="let m of filteredModel" [value]="m.name"
								(onSelectionChange)="selectModel(m.ID,$event.isUserInput)">
								{{m.name}}
							</mat-option>
						</mat-autocomplete>
					</mat-form-field> -->
					<ng-select appearance="outline" [items]="filteredModel" dropdownPosition="bottom" [markFirst]="true"
						[virtualScroll]="true" bindLabel="name" bindValue="ID" placeholder="Model"
						formControlName="model" id="modelField">
					</ng-select>
					<div *ngIf="(errorMsg2 && f.model.errors) || (submittedForm && f.model.errors)"
						class="text-danger margin-bottom-thin my-mat-hint" style="margin-top: -20px;">
						<div *ngIf="f.model.errors.required">The Make and Model is required</div>
					</div>
				</div>
				<div class="col-md-6">
					<mat-form-field appearance="outline" class="margin-zero">
						<mat-label>
							Number of Vehicles <span class="asterisk">*</span>
						</mat-label>
						<input matInput type="number" placeholder="Number of Vehicles"
							formControlName="numberOfVehicles" tabindex="1" (focus)="$event.target.select()">
					</mat-form-field>
					<div *ngIf="submittedForm && f.numberOfVehicles.errors || f.numberOfVehicles.touched && f.numberOfVehicles.errors"
						class="text-danger margin-bottom-thin my-mat-hint">
						<div *ngIf="f.numberOfVehicles.errors.required">The Number of Vehicles is required.</div>
					</div>
					<div *ngIf="f.numberOfVehicles.errors" class="text-danger margin-bottom-thin my-mat-hint">
						<div *ngIf="f.numberOfVehicles.errors.pattern">Enter Numbers Only.</div>
						<div *ngIf="f.numberOfVehicles.errors.min">Please enter atleast 1 vehicle</div>
						<div *ngIf="f.numberOfVehicles.errors.max">
							<div *ngIf="f.numberOfVehicles.errors.max.actual>f.numberOfVehicles.errors.max.max">You
								can't
								enter more than {{f.numberOfVehicles.errors.max.max}} vehicle</div>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<mat-form-field appearance="outline"
						[ngClass]="(errorMsg3 && f.year.errors) || (submittedForm && f.year.errors) ? 'mat-form-field-invalid ng-invalid' : ''">
						<mat-label>Year <span class="asterisk">*</span></mat-label>
						<input type="search" id="yearField" placeholder="Pick one" aria-label="Number" matInput
							(keyup)="searchYear($event.target.value)" [matAutocomplete]="auto4" tabindex="1"
							(focus)="$event.target.select()">
						<mat-autocomplete #auto4="matAutocomplete">
							<mat-option *ngFor="let year of filteredYear" [value]="year.name"
								(onSelectionChange)="selectYear(year.ID,$event.isUserInput)">
								{{year.name}}
							</mat-option>
						</mat-autocomplete>
					</mat-form-field>
					<div *ngIf="(errorMsg3 && f.year.errors) || (submittedForm && f.year.errors)"
						class="text-danger margin-bottom-thin my-mat-hint"
						style="margin-bottom: 30px;">
						<div *ngIf="f.year.errors.required">The Year is required</div>
					</div>
				</div>
				<div class="col-md-6">
					<mat-form-field appearance="outline"
						[ngClass]="(errorMsg4 && f.color.errors) || (submittedForm && f.color.errors) ? 'mat-form-field-invalid ng-invalid' : ''">
						<mat-label>Color <span class="asterisk">*</span></mat-label>
						<input type="search" id="colorField" placeholder="Pick one" aria-label="Number" matInput
							(keyup)="searchColor($event.target.value)" [matAutocomplete]="auto5" tabindex="1"
							(focus)="$event.target.select()">
						<mat-autocomplete #auto5="matAutocomplete">
							<mat-option *ngFor="let color of filteredColor" [value]="color.name"
								(onSelectionChange)="selectColor(color.ID,$event.isUserInput)">
								{{color.name}}
							</mat-option>
						</mat-autocomplete>
					</mat-form-field>
					<div *ngIf="(errorMsg4 && f.color.errors) || (submittedForm && f.color.errors)"
						class="text-danger margin-bottom-thin my-mat-hint">
						<div *ngIf="f.color.errors.required">The Color is required</div>
					</div>
				</div>
				<div class="col-md-6">
					<mat-form-field appearance="outline">
						<mat-label>Number of Pax Seats / Seat Belts <span class="asterisk">*</span></mat-label>
						<mat-select formControlName="seats" tabindex="1">
							<mat-option value="">Choose Pax Seats / Seat Belts</mat-option>
							<mat-option *ngFor="let seatOption of seatOptions" [value]="seatOption">{{seatOption}}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<div *ngIf="submittedForm && f.seats.errors || f.seats.touched && f.seats.errors"
						class="text-danger margin-bottom-thin my-mat-hint">
						<div *ngIf="f.seats.errors.required">The Pax Seats / Seat Belts is required</div>
					</div>
					<div *ngIf="f.seats.errors" class="text-danger margin-bottom-thin my-mat-hint">
						<div *ngIf="f.seats.errors.pattern">The Pax Seats / Seat Belts should be a number</div>
					</div>
				</div>
				<div class="col-md-6">
					<mat-form-field appearance="outline">
						<mat-label>Number of Luggage <span class="theme-color">(2 carry on = 1 large suitcase)</span>
							<span class="asterisk">*</span>
						</mat-label>
						<mat-select formControlName="luggage" tabindex="1">
							<mat-option value="">Choose Luggage</mat-option>
							<mat-option *ngFor="let luggageOption of luggageOptions" [value]="luggageOption">
								{{luggageOption}}</mat-option>
						</mat-select>
					</mat-form-field>
					<div *ngIf="submittedForm && f.luggage.errors || f.luggage.touched && f.luggage.errors"
						class="text-danger margin-bottom-thin my-mat-hint">
						<div *ngIf="f.luggage.errors.required">The Luggage is required</div>
					</div>
					<div *ngIf="f.luggage.errors" class="text-danger margin-bottom-thin my-mat-hint">
						<div *ngIf="f.luggage.errors.pattern">The Luggage should be a number</div>
					</div>
				</div>
				<div class="col-md-6">
					<mat-form-field appearance="outline">
						<mat-label>Airport / City / Cruise Port Cancel Policy <span class="asterisk">*</span></mat-label>
						<mat-select formControlName="nonCharterCancelPolicy" tabindex="1">
							<mat-option value="">Choose Airport / City / Cruise Port Cancel Policy</mat-option>
							<mat-option *ngFor="let nonCharterCancelOption of nonCharterCancelOptions"
								[value]="nonCharterCancelOption.value">{{nonCharterCancelOption.label}}</mat-option>
						</mat-select>
					</mat-form-field>
					<div *ngIf="submittedForm && f.nonCharterCancelPolicy.errors || f.nonCharterCancelPolicy.touched && f.nonCharterCancelPolicy.errors"
						class="text-danger margin-bottom-thin my-mat-hint">
						<div *ngIf="f.nonCharterCancelPolicy.errors.required">The Airport / City / Cruise Port Cancel Policy is required
						</div>
					</div>
					<div *ngIf="f.nonCharterCancelPolicy.errors" class="text-danger margin-bottom-thin my-mat-hint">
						<div *ngIf="f.nonCharterCancelPolicy.errors.pattern">The Airport / City / Cruise Port Cancel Policy should be a
							number
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<mat-form-field appearance="outline">
						<mat-label>Charter Cancel Policy <span class="asterisk">*</span></mat-label>
						<mat-select formControlName="charterCancelPolicy" tabindex="1">
							<mat-option value="">Choose Charter Cancel Policy</mat-option>
							<mat-option *ngFor="let charterCancelOption of charterCancelOptions"
								[value]="charterCancelOption.value">{{charterCancelOption.label}}</mat-option>
						</mat-select>
					</mat-form-field>
					<div *ngIf="submittedForm && f.charterCancelPolicy.errors || f.charterCancelPolicy.touched && f.charterCancelPolicy.errors"
						class="text-danger margin-bottom-thin my-mat-hint">
						<div *ngIf="f.charterCancelPolicy.errors.required">The Charter Cancel Policy is required
						</div>
					</div>
					<div *ngIf="f.charterCancelPolicy.errors" class="text-danger margin-bottom-thin my-mat-hint">
						<div *ngIf="f.charterCancelPolicy.errors.pattern">The Charter Cancel Policy should be a
							number</div>
					</div>
				</div>
				<div class="col-md-6">
					<mat-form-field appearance="outline">
						<mat-label>
							License plate # or other vehicle identifier
							<!-- <span *ngIf="affiliateType!='fleet_operator'; else nonFleetOperator"
								class="asterisk">*</span> -->
							<ng-template #nonFleetOperator>
								<span class="theme-color">(optional)</span>
							</ng-template>
						</mat-label>
						<input matInput placeholder="License plate # or other vehicle identifier" formControlName="licensePlate" tabindex="1"
							(focus)="$event.target.select()">
					</mat-form-field>
				</div>
				<!-- <div class="col-md-6">
					<mat-form-field appearance="outline">
						<mat-label>Transfer Cancellation Policy <span class="asterisk">*</span></mat-label>
						<mat-select formControlName="transferCancelPolicyQB" tabindex="1">
							<mat-option value="">Choose Transfer Cancel policy</mat-option>
							<mat-option *ngFor="let charterCancelOption of charterCancelOptions"
								[value]="charterCancelOption.value">{{charterCancelOption.label}}</mat-option>
						</mat-select>
					</mat-form-field>
					<div *ngIf="submittedForm && f.transferCancelPolicyQB?.errors || f.transferCancelPolicyQB?.touched && f.transferCancelPolicyQB?.errors"
						class="text-danger margin-bottom-thin my-mat-hint">
						<div *ngIf="f.transferCancelPolicyQB?.errors?.required">The Charter Cancel Times is required
						</div>
					</div>
					<div *ngIf="f.transferCancelPolicyQB?.errors" class="text-danger margin-bottom-thin my-mat-hint">
						<div *ngIf="f.transferCancelPolicyQB?.errors?.pattern">The Charter Cancel Times should be a
							number</div>
					</div>
				</div>
				<div class="col-md-6">
					<mat-form-field appearance="outline">
						<mat-label>Charter Cancellation Policy <span class="asterisk">*</span></mat-label>
						<mat-select formControlName="charterCancelPolicyQB" tabindex="1">
							<mat-option value="">Choose Charter Cancel policy</mat-option>
							<mat-option *ngFor="let charterCancelOption of charterCancelOptions"
								[value]="charterCancelOption.value">{{charterCancelOption.label}}</mat-option>
						</mat-select>
					</mat-form-field>
					<div *ngIf="submittedForm && f.charterCancelPolicyQB?.errors || f.charterCancelPolicyQB?.touched && f.charterCancelPolicyQB?.errors"
						class="text-danger margin-bottom-thin my-mat-hint">
						<div *ngIf="f.charterCancelPolicyQB?.errors?.required">The Charter Cancel Times is required
						</div>
					</div>
					<div *ngIf="f.charterCancelPolicyQB?.errors" class="text-danger margin-bottom-thin my-mat-hint">
						<div *ngIf="f.charterCancelPolicyQB?.errors?.pattern">The Charter Cancel Times should be a
							number</div>
					</div>
				</div> -->
			</div>
			<div class="row">
				<div class="col-md-12">
					<h3 class="addveh_top d-md-flex justify-content-between align-items-center mb-0 ml-1 mt-2">
						<label class="alert alert-danger" style="padding :0.25rem 1.25rem;">
							Choose all that apply.</label>
					</h3>
				</div>
			</div>
			<div class="row mt-3">
				<div class="col-md-4">
					<div class="input-checkbox">
						<div class="checkbox" [ngClass]="
								service.includes('local') ? 'active' : ''
							" (click)="onServiceChange('local')">
							Local Service Only
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="input-checkbox">
						<div class="checkbox" [ngClass]="
								service.includes('over_the_road')
									? 'active'
									: ''
							" (click)="onServiceChange('over_the_road')">
							Over The Road
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="input-checkbox">
						<div class="checkbox" [ngClass]="
								service.includes('shuttle') ? 'active' : ''
							" (click)="onServiceChange('shuttle')">
							Shuttle Service
						</div>
					</div>
				</div>
			</div>

		</div>
	</section>
	<section class="user_information">
		<h2 class="headinginfo"> Choose Chargable Amenities <span class="asterisk">* </span> <span>( Choose each
				chargable amenity you supply or
				provide )</span> </h2>
		<div class="container mt-1">
			<div class="choose_ameniteslist mt-3" *ngFor="let chargableAmenityGroup of chargableAmenities | keyvalue">
				<div class="custom-control custom-checkbox"
					*ngFor="let chargableAmenity of chargableAmenityGroup.value | keyvalue">
					<!-- {{chargableAmenity.value | json}}<br> -->
					<input type="checkbox" class="custom-control-input" id="amenity_{{chargableAmenity.value.name}}"
						[value]="chargableAmenity.value.id"
						(change)="onAmenitiesCheckboxChange($event.target.value,$event.target.checked)" tabindex="1"
						(focus)="$event.target.select()">
					<label class="custom-control-label text-wrap" style="width: 100px;"
						for="amenity_{{chargableAmenity.value.name}}">{{chargableAmenity.value.name}}</label>
					<label *ngIf="chargableAmenity.value.chargeable==='yes'" class="amenity-dollar">$</label>
				</div>
			</div>
			<div *ngIf="submittedForm && f.amenities.errors" class="text-danger margin-bottom-thin my-mat-hint">
				<div *ngIf="f.amenities.errors.required">Please select atleast one Chargable Amenity.</div>
			</div>
		</div>

		<h2 class="headinginfo topMargin"> Choose Non Chargable Amenities <span class="asterisk">* </span> <span>(
				Choose
				each non chargable amenity
				you supply or provide )</span> </h2>
		<div class="container mt-1 non-chargable-amenity">
			<div class="choose_ameniteslist mt-3"
				*ngFor="let nonChargableAmenityGroup of nonChargableAmenities | keyvalue">
				<div class="custom-control custom-checkbox"
					*ngFor="let nonChargableAmenity of nonChargableAmenityGroup.value | keyvalue">
					<!-- {{nonChargableAmenity.value | json}}<br> -->
					<input type="checkbox" class="custom-control-input" id="amenity_{{nonChargableAmenity.value.name}}"
						[value]="nonChargableAmenity.value.id"
						(change)="onAmenitiesCheckboxChange($event.target.value,$event.target.checked)" tabindex="1"
						(focus)="$event.target.select()">
					<label class="custom-control-label"
						for="amenity_{{nonChargableAmenity.value.name}}">{{nonChargableAmenity.value.name}}</label>
					<label *ngIf="nonChargableAmenity.value.chargeable==='yes'" class="amenity-dollar">&nbsp;
						$</label>
				</div>
			</div>
			<div *ngIf="submittedForm && f.amenities.errors" class="text-danger margin-bottom-thin my-mat-hint">
				<div *ngIf="f.amenities.errors.required">Please select atleast one Non Chargable Amenity.</div>
			</div>
		</div>
	</section>

	<section class="user_information">
		<h2 class="headinginfo"> Choose Special Amenities <span>( Choose each special amenity you supply or provide
				)</span></h2>
		<div class="container mt-1 ">
			<div class="choose_ameniteslist">
				<div class="custom-control custom-checkbox" *ngFor="let specialAmenity of specialAmenities">
					<input type="checkbox" class="custom-control-input" id="specialAmenity_{{specialAmenity.name}}"
						[value]="specialAmenity.id" (change)="onSpecialAmenitiesCheckboxChange($event)" tabindex="1"
						(focus)="$event.target.select()">
					<label class="custom-control-label"
						for="specialAmenity_{{specialAmenity.name}}">{{specialAmenity.name}}</label>
				</div>
			</div>
			<div *ngIf="submittedForm && f.specialAmenities.errors" class="text-danger margin-bottom-thin my-mat-hint">
				<div *ngIf="f.specialAmenities.errors.required">Please select atleast one Special Amenity.</div>
			</div>
		</div>
	</section>
	<section class="user_information">
		<h2 class="headinginfo"> Choose Interiors <span class="asterisk">* </span> <span>( Choose each interior option
				for this vehicle )</span>
		</h2>
		<div class="container mt-1 ">
			<div class="choose_ameniteslist">
				<div class="custom-control custom-checkbox" *ngFor="let interior of interiors">
					<input type="checkbox" class="custom-control-input" id="interior_{{interior.name}}"
						[value]="interior.id" (change)="onInteriorsCheckboxChange($event)" tabindex="1"
						[checked]="interior.id=='1'" (focus)="$event.target.select()">
					<label class="custom-control-label" for="interior_{{interior.name}}">{{interior.name}}</label>
				</div>
			</div>
			<div *ngIf="submittedForm && f.vehicleInterior.errors" class="text-danger margin-bottom-thin my-mat-hint">
				<div *ngIf="f.vehicleInterior.errors.required">Please select atleast one Interior.</div>
			</div>
		</div>
	</section>
	<!-- Main page content-->
	<section class="user_information">
		<h2 class="headinginfo">Exterior and Interior <span>( Exterior And Interior – <svg data-toggle="modal"
					data-placement="top" title="Click for Photo Tip" data-target="#photoInstructionsModal"
					class="camera-svg" viewBox="0 0 297.88 216" xml:space="preserve" tabindex="1">
					<style type="text/css">
						.st0 {
							fill: #F89B41;
						}
					</style>
					<g>
						<path
							d="M57.73,47.19c0.17-3.23,0.3-5.8,0.47-8.98c6.9,0,13.6,0,20.74,0c0.26,2.87,0.51,5.63,0.79,8.61c4.85,2.05,8.32,0.5,11.68-3.37c5.76-6.65,11.9-12.97,17.97-19.34c4.68-4.91,10.32-7,17.28-6.84c14.99,0.36,30.01,0.46,45-0.03c8.1-0.27,14.34,2.5,19.64,8.19c5.67,6.1,11.07,12.47,17.05,18.25c2.12,2.05,5.54,3.7,8.45,3.82c10.7,0.45,21.43,0.07,32.14,0.22c13.32,0.19,20.64,7.58,20.66,20.97c0.07,35.72,0.09,71.44-0.01,107.16c-0.04,14.4-8.32,22.48-22.68,22.48c-64.53,0.02-129.07,0.02-193.6,0c-16.43,0-25.07-8.65-25.08-25.05c-0.01-33.34,0-66.68,0-100.01c0-18.26,6.49-25,24.58-25.6C54.21,47.63,55.62,47.4,57.73,47.19z M149.37,188.59c32.38,0,64.76,0.02,97.15-0.01c8.93-0.01,13.11-3.63,13.15-12.17c0.14-36.19,0.06-72.38,0.05-108.57c0-6.7-3.73-10.12-10.1-10.5c-5.46-0.33-11.07-0.84-16.4,0.03c-20.18,3.29-35.55-3.2-45.9-21.06c-0.82-1.41-2.26-2.44-3.31-3.74c-3.39-4.21-7.59-5.89-13.11-5.54c-6.88,0.44-13.8,0.11-20.71,0.11c-32.41,0-32.29,0.09-52.92,25.15c-2.17,2.63-6.62,4.55-10.11,4.72c-11.88,0.58-23.8,0.08-35.7,0.27c-9.49,0.15-13.3,4.19-13.3,13.61c-0.02,34.05-0.02,68.1,0,102.15c0.01,11.14,4.38,15.54,15.52,15.55C85.55,188.61,117.46,188.59,149.37,188.59z" />
						<path
							d="M149.05,173.22c-29.81,0.07-53.72-23.67-53.72-53.33c0-29.51,24.04-53.77,53.46-53.95c29.21-0.17,53.59,24.19,53.73,53.69C202.66,149.29,178.84,173.16,149.05,173.22z M148.82,163.33c24.3,0.04,43.7-19.14,43.77-43.27c0.06-24.41-19.75-44.33-43.91-44.15c-23.98,0.18-43.41,19.84-43.41,43.93C105.27,144.05,124.53,163.29,148.82,163.33z" />
						<path
							d="M219.39,78.12c-3.52,0-6.28,0-9.42,0c0-3.27,0-6.23,0-9.65c3.15,0,6.08,0,9.42,0C219.39,71.66,219.39,74.61,219.39,78.12z" />
						<path class="st0"
							d="M129.22,119.75c-0.02-11.43,8.36-19.82,19.76-19.8c11.39,0.02,19.68,8.43,19.64,19.91c-0.04,11.25-8.79,20.18-19.76,20.14C137.87,139.96,129.24,131.07,129.22,119.75z" />
					</g>
				</svg> Photo Tip – Take Interior Pix On Cloudy Days )</span></h2>
		<div class="container mt-1">
			<span class="alert alert-danger row ml-2 mr-2 mt-3 mb-2">Upload the pictures of Interior and Exterior of
				the
				vehicle
			</span>
			<span class="alert alert-danger row ml-2 mr-2">These are sample images as a guide to good picture
				taking.
				Sell your unique features that travelers and party goers want!
			</span>
			<div class="mt-4 vehtype_extintwrap">
				<div class="row">
					<div class="col-md-4" *ngIf="vehicleImage1">
						<div class="vehtype_extint">
							<p>
								<span class="text-danger">
									{{ vehicleImage1.includes('extint1') ? 'Change' : '' }}
								</span>
								Vehicle Image 1 <span class="asterisk">*</span>
							</p>
							<img src="{{ vehicleImage1 }}" (click)="showImageInModal(vehicleImage1)" tabindex="-1" />
							<ul class="mt-3">
								<li>
									<a class="custom-arrow-clockwise-button"
										(click)="fetchImageBlob(vehicleImage1 ,'1',vehicleImageId1)">
										<i class="bi bi-arrow-clockwise"></i>rotate</a>
								</li>
								<li>
									<button class="changeuploaded" tabindex="1">
										<input type="file" (change)="
												onFileChange(
													$event,
													vehicleImageId1,
													'1'
												)
											" tabindex="-1" accept="image/jpeg, image/png" />
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
											<path fill="none" d="M0 0h24v24H0V0z" />
											<circle cx="12" cy="12" r="3" />
											<path
												d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
										</svg>
										Change
									</button>
								</li>
							</ul>
						</div>
					</div>

					<div class="col-md-4" *ngIf="vehicleImage2; else noVehicleImage2">
						<div class="vehtype_extint">
							<p>Vehicle Image 2</p>
							<img src="{{ vehicleImage2 }}" (click)="showImageInModal(vehicleImage2)" tabindex="-1" />
							<ul class="mt-3">
								<li>
									<a class="custom-arrow-clockwise-button"
										(click)="fetchImageBlob(vehicleImage2,'2',vehicleImageId2)">
										<i class="bi bi-arrow-clockwise"></i>rotate</a>
								</li>
								<li>
									<button class="changeuploaded" tabindex="1">
										<input type="file" (change)="
												onFileChange(
													$event,
													vehicleImageId2,
													'2'
												)
											" tabindex="-1" accept="image/jpeg, image/png" />
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
											<path fill="none" d="M0 0h24v24H0V0z" />
											<circle cx="12" cy="12" r="3" />
											<path
												d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
										</svg>
										Change
									</button>
								</li>
								<li>
									<a class="delete-btn" (click)="
											deleteImage(
												vehicleImageId2,
												'vehicleImage',
												'2'
											)
										" tabindex="1">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
											<path
												d="M30,5H22V4a3,3,0,0,0-3-3H13a3,3,0,0,0-3,3V5H2A1,1,0,0,0,2,7H5V26a5.006,5.006,0,0,0,5,5H22a5.006,5.006,0,0,0,5-5V7h3a1,1,0,0,0,0-2ZM12,4a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1V5H12ZM25,26a3,3,0,0,1-3,3H10a3,3,0,0,1-3-3V7H25Z" />
										</svg>
										Delete</a>
								</li>
							</ul>
						</div>
					</div>
					<ng-template #noVehicleImage2>
						<div class="col-md-4">
							<div class="vehtype_extint">
								<p>Vehicle Image 2</p>
								<div class="emptyImage">
									<img src="../../../../assets/images/empty-image.png" tabindex="-1" />
								</div>
								<ul class="mt-3">
									<li>
										<button class="adduploaded" tabindex="1">
											<input type="file" (change)="
													onFileChange(
														$event,
														vehicleImageId2,
														'2'
													)
												" tabindex="-1" accept="image/jpeg, image/png" />
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
												<path fill="none" d="M0 0h24v24H0V0z" />
												<circle cx="12" cy="12" r="3" />
												<path
													d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
											</svg>
											Add
										</button>
									</li>
								</ul>
							</div>
						</div>
					</ng-template>

					<div class="col-md-4" *ngIf="vehicleImage3; else noVehicleImage3">
						<div class="vehtype_extint">
							<p>Vehicle Image 3</p>
							<img src="{{ vehicleImage3 }}" (click)="showImageInModal(vehicleImage3)" tabindex="-1" />
							<ul class="mt-3">
								<li>
									<a class="custom-arrow-clockwise-button"
										(click)="fetchImageBlob(vehicleImage3,'3',vehicleImageId3)">
										<i class="bi bi-arrow-clockwise"></i>rotate</a>
								</li>
								<li>
									<button class="changeuploaded" tabindex="1">
										<input type="file" (change)="
												onFileChange(
													$event,
													vehicleImageId3,
													'3'
												)
											" tabindex="-1" accept="image/jpeg, image/png" />
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
											<path fill="none" d="M0 0h24v24H0V0z" />
											<circle cx="12" cy="12" r="3" />
											<path
												d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
										</svg>
										Change
									</button>
								</li>
								<li>
									<a class="delete-btn" (click)="
											deleteImage(
												vehicleImageId3,
												'vehicleImage',
												'3'
											)
										" tabindex="1">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
											<path
												d="M30,5H22V4a3,3,0,0,0-3-3H13a3,3,0,0,0-3,3V5H2A1,1,0,0,0,2,7H5V26a5.006,5.006,0,0,0,5,5H22a5.006,5.006,0,0,0,5-5V7h3a1,1,0,0,0,0-2ZM12,4a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1V5H12ZM25,26a3,3,0,0,1-3,3H10a3,3,0,0,1-3-3V7H25Z" />
										</svg>
										Delete</a>
								</li>
							</ul>
						</div>
					</div>
					<ng-template #noVehicleImage3>
						<div class="col-md-4">
							<div class="vehtype_extint">
								<p>Vehicle Image 3</p>
								<div class="emptyImage">
									<img src="../../../../assets/images/empty-image.png" tabindex="-1" />
								</div>
								<ul class="mt-3">
									<li>
										<button class="adduploaded" tabindex="1">
											<input type="file" (change)="
													onFileChange(
														$event,
														vehicleImageId3,
														'3'
													)
												" tabindex="-1" accept="image/jpeg, image/png" />
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
												<path fill="none" d="M0 0h24v24H0V0z" />
												<circle cx="12" cy="12" r="3" />
												<path
													d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
											</svg>
											Add
										</button>
									</li>
								</ul>
							</div>
						</div>
					</ng-template>

					<div class="col-md-4" *ngIf="vehicleImage4; else noVehicleImage4">
						<div class="vehtype_extint">
							<p>Vehicle Image 4</p>
							<img src="{{ vehicleImage4 }}" (click)="showImageInModal(vehicleImage4)" tabindex="-1" />
							<ul class="mt-3">
								<li>
									<a class="custom-arrow-clockwise-button"
										(click)="fetchImageBlob(vehicleImage4,'4',vehicleImageId4)">
										<i class="bi bi-arrow-clockwise"></i>rotate</a>
								</li>
								<li>
									<button class="changeuploaded" tabindex="1">
										<input type="file" (change)="
												onFileChange(
													$event,
													vehicleImageId4,
													'4'
												)
											" tabindex="-1" accept="image/jpeg, image/png" />
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
											<path fill="none" d="M0 0h24v24H0V0z" />
											<circle cx="12" cy="12" r="3" />
											<path
												d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
										</svg>
										Change
									</button>
								</li>
								<li>
									<a class="delete-btn" (click)="
											deleteImage(
												vehicleImageId4,
												'vehicleImage',
												'4'
											)
										" tabindex="1">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
											<path
												d="M30,5H22V4a3,3,0,0,0-3-3H13a3,3,0,0,0-3,3V5H2A1,1,0,0,0,2,7H5V26a5.006,5.006,0,0,0,5,5H22a5.006,5.006,0,0,0,5-5V7h3a1,1,0,0,0,0-2ZM12,4a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1V5H12ZM25,26a3,3,0,0,1-3,3H10a3,3,0,0,1-3-3V7H25Z" />
										</svg>
										Delete</a>
								</li>
							</ul>
						</div>
					</div>
					<ng-template #noVehicleImage4>
						<div class="col-md-4">
							<div class="vehtype_extint">
								<p>Vehicle Image 4</p>
								<div class="emptyImage">
									<img src="../../../../assets/images/empty-image.png" tabindex="-1" />
								</div>
								<ul class="mt-3">
									<li>
										<button class="adduploaded" tabindex="1">
											<input type="file" (change)="
													onFileChange(
														$event,
														vehicleImageId4,
														'4'
													)
												" tabindex="-1" accept="image/jpeg, image/png" />
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
												<path fill="none" d="M0 0h24v24H0V0z" />
												<circle cx="12" cy="12" r="3" />
												<path
													d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
											</svg>
											Add
										</button>
									</li>
								</ul>
							</div>
						</div>
					</ng-template>

					<div class="col-md-4" *ngIf="vehicleImage5; else noVehicleImage5">
						<div class="vehtype_extint">
							<p>Vehicle Image 5</p>
							<img src="{{ vehicleImage5 }}" (click)="showImageInModal(vehicleImage5)" tabindex="-1" />
							<ul class="mt-3">
								<li>
									<a class="custom-arrow-clockwise-button"
										(click)="fetchImageBlob(vehicleImage5,'5',vehicleImageId5)">
										<i class="bi bi-arrow-clockwise"></i>rotate</a>
								</li>
								<li>
									<button class="changeuploaded" tabindex="1">
										<input type="file" (change)="
												onFileChange(
													$event,
													vehicleImageId5,
													'5'
												)
											" tabindex="-1" accept="image/jpeg, image/png" />
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
											<path fill="none" d="M0 0h24v24H0V0z" />
											<circle cx="12" cy="12" r="3" />
											<path
												d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
										</svg>
										Change
									</button>
								</li>
								<li>
									<a class="delete-btn" (click)="
											deleteImage(
												vehicleImageId5,
												'vehicleImage',
												'5'
											)
										" tabindex="1">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
											<path
												d="M30,5H22V4a3,3,0,0,0-3-3H13a3,3,0,0,0-3,3V5H2A1,1,0,0,0,2,7H5V26a5.006,5.006,0,0,0,5,5H22a5.006,5.006,0,0,0,5-5V7h3a1,1,0,0,0,0-2ZM12,4a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1V5H12ZM25,26a3,3,0,0,1-3,3H10a3,3,0,0,1-3-3V7H25Z" />
										</svg>
										Delete</a>
								</li>
							</ul>
						</div>
					</div>
					<ng-template #noVehicleImage5>
						<div class="col-md-4">
							<div class="vehtype_extint">
								<p>Vehicle Image 5</p>
								<div class="emptyImage">
									<img src="../../../../assets/images/empty-image.png" tabindex="-1" />
								</div>
								<ul class="mt-3">
									<li>
										<button class="adduploaded" tabindex="1">
											<input type="file" (change)="
													onFileChange(
														$event,
														vehicleImageId5,
														'5'
													)
												" tabindex="-1" accept="image/jpeg, image/png" />
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
												<path fill="none" d="M0 0h24v24H0V0z" />
												<circle cx="12" cy="12" r="3" />
												<path
													d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
											</svg>
											Add
										</button>
									</li>
								</ul>
							</div>
						</div>
					</ng-template>

					<div class="col-md-4" *ngIf="vehicleImage6; else noVehicleImage6">
						<div class="vehtype_extint">
							<p>Vehicle Image 6</p>
							<img src="{{ vehicleImage6 }}" (click)="showImageInModal(vehicleImage6)" tabindex="-1" />
							<ul class="mt-3">
								<li>
									<a class="custom-arrow-clockwise-button"
										(click)="fetchImageBlob(vehicleImage6,'6',vehicleImageId6)">
										<i class="bi bi-arrow-clockwise"></i>rotate</a>
								</li>
								<li>
									<button class="changeuploaded" tabindex="1">
										<input type="file" (change)="
												onFileChange(
													$event,
													vehicleImageId6,
													'6'
												)
											" tabindex="-1" accept="image/jpeg, image/png" />
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
											<path fill="none" d="M0 0h24v24H0V0z" />
											<circle cx="12" cy="12" r="3" />
											<path
												d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
										</svg>
										Change
									</button>
								</li>
								<li>
									<a class="delete-btn" (click)="
											deleteImage(
												vehicleImageId6,
												'vehicleImage',
												'6'
											)
										" tabindex="1">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
											<path
												d="M30,5H22V4a3,3,0,0,0-3-3H13a3,3,0,0,0-3,3V5H2A1,1,0,0,0,2,7H5V26a5.006,5.006,0,0,0,5,5H22a5.006,5.006,0,0,0,5-5V7h3a1,1,0,0,0,0-2ZM12,4a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1V5H12ZM25,26a3,3,0,0,1-3,3H10a3,3,0,0,1-3-3V7H25Z" />
										</svg>
										Delete</a>
								</li>
							</ul>
						</div>
					</div>
					<ng-template #noVehicleImage6>
						<div class="col-md-4">
							<div class="vehtype_extint">
								<p>Vehicle Image 6</p>
								<div class="emptyImage">
									<img src="../../../../assets/images/empty-image.png" tabindex="-1" />
								</div>
								<ul class="mt-3">
									<li>
										<button class="adduploaded" tabindex="1">
											<input type="file" (change)="
													onFileChange(
														$event,
														vehicleImageId6,
														'6'
													)
												" tabindex="-1" accept="image/jpeg, image/png" />
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
												<path fill="none" d="M0 0h24v24H0V0z" />
												<circle cx="12" cy="12" r="3" />
												<path
													d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
											</svg>
											Add
										</button>
									</li>
								</ul>
							</div>
						</div>
					</ng-template>

					<div class="col-md-4" *ngIf="vehicleImage7; else noVehicleImage7">
						<div class="vehtype_extint">
							<p>Vehicle Image 7</p>
							<img src="{{ vehicleImage7 }}" (click)="showImageInModal(vehicleImage7)" tabindex="-1" />
							<ul class="mt-3">
								<li>
									<a class="custom-arrow-clockwise-button"
										(click)="fetchImageBlob(vehicleImage7,'7',vehicleImageId7)">
										<i class="bi bi-arrow-clockwise"></i>rotate</a>
								</li>
								<li>
									<button class="changeuploaded" tabindex="1">
										<input type="file" (change)="
												onFileChange(
													$event,
													vehicleImageId7,
													'7'
												)
											" tabindex="-1" accept="image/jpeg, image/png" />
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
											<path fill="none" d="M0 0h24v24H0V0z" />
											<circle cx="12" cy="12" r="3" />
											<path
												d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
										</svg>
										Change
									</button>
								</li>
								<li>
									<a class="delete-btn" (click)="
											deleteImage(
												vehicleImageId7,
												'vehicleImage',
												'7'
											)
										" tabindex="1">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
											<path
												d="M30,5H22V4a3,3,0,0,0-3-3H13a3,3,0,0,0-3,3V5H2A1,1,0,0,0,2,7H5V26a5.006,5.006,0,0,0,5,5H22a5.006,5.006,0,0,0,5-5V7h3a1,1,0,0,0,0-2ZM12,4a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1V5H12ZM25,26a3,3,0,0,1-3,3H10a3,3,0,0,1-3-3V7H25Z" />
										</svg>
										Delete</a>
								</li>
							</ul>
						</div>
					</div>
					<ng-template #noVehicleImage7>
						<div class="col-md-4">
							<div class="vehtype_extint">
								<p>Vehicle Image 7</p>
								<div class="emptyImage">
									<img src="../../../../assets/images/empty-image.png" tabindex="-1" />
								</div>
								<ul class="mt-3">
									<li>
										<button class="adduploaded" tabindex="1">
											<input type="file" (change)="
													onFileChange(
														$event,
														vehicleImageId7,
														'7'
													)
												" tabindex="-1" accept="image/jpeg, image/png" />
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
												<path fill="none" d="M0 0h24v24H0V0z" />
												<circle cx="12" cy="12" r="3" />
												<path
													d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
											</svg>
											Add
										</button>
									</li>
								</ul>
							</div>
						</div>
					</ng-template>

					<div class="col-md-4" *ngIf="vehicleImage8; else noVehicleImage8">
						<div class="vehtype_extint">
							<p>Vehicle Image 8</p>
							<img src="{{ vehicleImage8 }}" (click)="showImageInModal(vehicleImage8)" tabindex="-1" />
							<ul class="mt-3">
								<li>
									<a class="custom-arrow-clockwise-button"
										(click)="fetchImageBlob(vehicleImage8,'8',vehicleImageId8)">
										<i class="bi bi-arrow-clockwise"></i>rotate</a>
								</li>
								<li>
									<button class="changeuploaded" tabindex="1">
										<input type="file" (change)="
												onFileChange(
													$event,
													vehicleImageId8,
													'8'
												)
											" tabindex="-1" accept="image/jpeg, image/png" />
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
											<path fill="none" d="M0 0h24v24H0V0z" />
											<circle cx="12" cy="12" r="3" />
											<path
												d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
										</svg>
										Change
									</button>
								</li>
								<li>
									<a class="delete-btn" (click)="
											deleteImage(
												vehicleImageId8,
												'vehicleImage',
												'8'
											)
										" tabindex="1">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
											<path
												d="M30,5H22V4a3,3,0,0,0-3-3H13a3,3,0,0,0-3,3V5H2A1,1,0,0,0,2,7H5V26a5.006,5.006,0,0,0,5,5H22a5.006,5.006,0,0,0,5-5V7h3a1,1,0,0,0,0-2ZM12,4a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1V5H12ZM25,26a3,3,0,0,1-3,3H10a3,3,0,0,1-3-3V7H25Z" />
										</svg>
										Delete</a>
								</li>
							</ul>
						</div>
					</div>
					<ng-template #noVehicleImage8>
						<div class="col-md-4">
							<div class="vehtype_extint">
								<p>Vehicle Image 8</p>
								<div class="emptyImage">
									<img src="../../../../assets/images/empty-image.png" tabindex="-1" />
								</div>
								<ul class="mt-3">
									<li>
										<button class="adduploaded" tabindex="1">
											<input type="file" (change)="
													onFileChange(
														$event,
														vehicleImageId8,
														'8'
													)
												" tabindex="-1" accept="image/jpeg, image/png" />
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
												<path fill="none" d="M0 0h24v24H0V0z" />
												<circle cx="12" cy="12" r="3" />
												<path
													d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
											</svg>
											Add
										</button>
									</li>
								</ul>
							</div>
						</div>
					</ng-template>

					<div class="col-md-4" *ngIf="vehicleImage9; else noVehicleImage9">
						<div class="vehtype_extint">
							<p>Vehicle Image 9</p>
							<img src="{{ vehicleImage9 }}" (click)="showImageInModal(vehicleImage9)" tabindex="-1" />
							<ul class="mt-3">
								<li>
									<a class="custom-arrow-clockwise-button"
										(click)="fetchImageBlob(vehicleImage9,'9',vehicleImageId9)">
										<i class="bi bi-arrow-clockwise"></i>rotate</a>
								</li>
								<li>
									<button class="changeuploaded" tabindex="1">
										<input type="file" (change)="
												onFileChange(
													$event,
													vehicleImageId9,
													'9'
												)
											" tabindex="-1" accept="image/jpeg, image/png" />
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
											<path fill="none" d="M0 0h24v24H0V0z" />
											<circle cx="12" cy="12" r="3" />
											<path
												d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
										</svg>
										Change
									</button>
								</li>
								<li>
									<a class="delete-btn" (click)="
											deleteImage(
												vehicleImageId9,
												'vehicleImage',
												'9'
											)
										" tabindex="1">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
											<path
												d="M30,5H22V4a3,3,0,0,0-3-3H13a3,3,0,0,0-3,3V5H2A1,1,0,0,0,2,7H5V26a5.006,5.006,0,0,0,5,5H22a5.006,5.006,0,0,0,5-5V7h3a1,1,0,0,0,0-2ZM12,4a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1V5H12ZM25,26a3,3,0,0,1-3,3H10a3,3,0,0,1-3-3V7H25Z" />
										</svg>
										Delete</a>
								</li>
							</ul>
						</div>
					</div>
					<ng-template #noVehicleImage9>
						<div class="col-md-4">
							<div class="vehtype_extint">
								<p>Vehicle Image 9</p>
								<div class="emptyImage">
									<img src="../../../../assets/images/empty-image.png" tabindex="-1" />
								</div>
								<ul class="mt-3">
									<li>
										<button class="adduploaded" tabindex="1">
											<input type="file" (change)="
													onFileChange(
														$event,
														vehicleImageId9,
														'9'
													)
												" tabindex="-1" accept="image/jpeg, image/png" />
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
												<path fill="none" d="M0 0h24v24H0V0z" />
												<circle cx="12" cy="12" r="3" />
												<path
													d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
											</svg>
											Add
										</button>
									</li>
								</ul>
							</div>
						</div>
					</ng-template>
				</div>

				<hr />

				<div class="row" *ngIf="affiliateType != 'fleet_operator'">
					<div class="col-md-4" *ngIf="rearPlateImage; else noRearPlateImage">
						<div class="vehtype_extint">
							<div>
								<p>
									Rear vehicle license plate
									<!-- <span class="asterisk">* </span> -->
									<span class="optionalTextStyle">(w/exp decal)
									</span>
								</p>
								<img src="{{ rearPlateImage }}" (click)="showImageInModal(rearPlateImage)"
									tabindex="-1" />
								<ul class="mt-3">
									<li>
										<a class="custom-arrow-clockwise-button"
											(click)="fetchImageBlob(rearPlateImage,'rearPlate',rearPlateId)">
											<i class="bi bi-arrow-clockwise"></i>rotate</a>
									</li>
									<li>
										<button class="changeuploaded" tabindex="1">
											<input type="file" (change)="
													vehicleOfficialImagesChange(
														$event,
														'rearPlate',
														rearPlateId
													)
												" tabindex="-1" accept="image/jpeg, image/png" />
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
												<path fill="none" d="M0 0h24v24H0V0z" />
												<circle cx="12" cy="12" r="3" />
												<path
													d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
											</svg>
											Change
										</button>
									</li>
									<li>
										<a class="delete-btn" (click)="
												deleteImage(
													rearPlateId,
													'rearPlate'
												)
											" tabindex="1">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
												<path
													d="M30,5H22V4a3,3,0,0,0-3-3H13a3,3,0,0,0-3,3V5H2A1,1,0,0,0,2,7H5V26a5.006,5.006,0,0,0,5,5H22a5.006,5.006,0,0,0,5-5V7h3a1,1,0,0,0,0-2ZM12,4a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1V5H12ZM25,26a3,3,0,0,1-3,3H10a3,3,0,0,1-3-3V7H25Z" />
											</svg>
											Delete</a>
									</li>
								</ul>
							</div>
						</div>
						<div *ngIf="submittedForm && f.rearPlateImage.errors"
							class="text-danger margin-bottom-thin my-mat-hint">
							<div *ngIf="f.rearPlateImage.errors.required">
								The Rear vehicle license plate is required
							</div>
						</div>
					</div>
					<ng-template #noRearPlateImage>
						<div class="col-md-4">
							<div class="vehtype_extint">
								<div>
									<p>
										Rear vehicle license plate
										<!-- <span class="asterisk">*</span> -->
									</p>
									<div class="emptyImage">
										<img src="../../../../assets/images/empty-image.png" tabindex="-1" />
									</div>
									<ul class="mt-3">
										<li>
											<button class="adduploaded" tabindex="1">
												<input type="file" (change)="
														vehicleOfficialImagesChange(
															$event,
															'rearPlate',
															rearPlateId
														)
													" tabindex="-1" accept="image/jpeg, image/png" />
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
													<path fill="none" d="M0 0h24v24H0V0z" />
													<circle cx="12" cy="12" r="3" />
													<path
														d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
												</svg>
												Add
											</button>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</ng-template>

					<div class="col-md-4" *ngIf="windowPermitImage; else noWindowPermitImage">
						<div class="vehtype_extint">
							<div>
								<p>
									Window Permit
									<!-- <span *ngIf="affiliateType != 'fleet_operator'" class="asterisk">*</span> -->
								</p>
								<img src="{{ windowPermitImage }}" (click)="
										showImageInModal(windowPermitImage)
									" tabindex="-1" />
								<ul class="mt-3">
									<li>
										<a class="custom-arrow-clockwise-button"
											(click)="fetchImageBlob(windowPermitImage,'windowPermit',windowPermitId)">
											<i class="bi bi-arrow-clockwise"></i>rotate</a>
									</li>
									<li>
										<button class="changeuploaded" tabindex="1">
											<input type="file" (change)="
													vehicleOfficialImagesChange(
														$event,
														'windowPermit',
														windowPermitId
													)
												" tabindex="-1" accept="image/jpeg, image/png" />
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
												<path fill="none" d="M0 0h24v24H0V0z" />
												<circle cx="12" cy="12" r="3" />
												<path
													d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
											</svg>
											Change
										</button>
									</li>
									<li>
										<a class="delete-btn" (click)="
												deleteImage(
													windowPermitId,
													'windowPermit'
												)
											" tabindex="1">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
												<path
													d="M30,5H22V4a3,3,0,0,0-3-3H13a3,3,0,0,0-3,3V5H2A1,1,0,0,0,2,7H5V26a5.006,5.006,0,0,0,5,5H22a5.006,5.006,0,0,0,5-5V7h3a1,1,0,0,0,0-2ZM12,4a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1V5H12ZM25,26a3,3,0,0,1-3,3H10a3,3,0,0,1-3-3V7H25Z" />
											</svg>
											Delete</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<ng-template #noWindowPermitImage>
						<div class="col-md-4">
							<div class="vehtype_extint">
								<div>
									<p>
										Window Permit
										<!-- <span *ngIf="affiliateType !='fleet_operator'" class="asterisk">*</span> -->
									</p>
									<div class="emptyImage">
										<img src="../../../../assets/images/empty-image.png" tabindex="-1" />
									</div>
									<ul class="mt-3">
										<li>
											<button class="adduploaded" tabindex="1">
												<input type="file" (change)="
														vehicleOfficialImagesChange(
															$event,
															'windowPermit',
															windowPermitId
														)
													" tabindex="-1" accept="image/jpeg, image/png" />
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
													<path fill="none" d="M0 0h24v24H0V0z" />
													<circle cx="12" cy="12" r="3" />
													<path
														d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
												</svg>
												Add
											</button>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</ng-template>

					<div class="col-md-4" *ngIf="windowPermit2Image; else noWindowPermit2Image">
						<div class="vehtype_extint">
							<div>
								<p>
									Secretary of State
									<!-- <span *ngIf="
											affiliateType != 'fleet_operator';
											else nonFleetOperator
										" class="asterisk">*</span> -->
									<ng-template #nonFleetOperator>
										<span class="optionalTextStyle">(optional)</span>
									</ng-template>
								</p>
								<img src="{{ windowPermit2Image }}" (click)="
										showImageInModal(windowPermit2Image)
									" tabindex="-1" />
								<ul class="mt-3">
									<li>
										<a class="custom-arrow-clockwise-button"
											(click)="fetchImageBlob(windowPermit2Image,'windowPermit2',windowPermit2Id)">
											<i class="bi bi-arrow-clockwise"></i>rotate</a>
									</li>
									<li>
										<button class="changeuploaded" tabindex="1">
											<input type="file" (change)="
													vehicleOfficialImagesChange(
														$event,
														'windowPermit2',
														windowPermit2Id
													)
												" tabindex="-1" accept="image/jpeg, image/png" />
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
												<path fill="none" d="M0 0h24v24H0V0z" />
												<circle cx="12" cy="12" r="3" />
												<path
													d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
											</svg>
											Change
										</button>
									</li>
									<li>
										<a class="delete-btn" (click)="
												deleteImage(
													windowPermit2Id,
													'windowPermit2'
												)
											" tabindex="1">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
												<path
													d="M30,5H22V4a3,3,0,0,0-3-3H13a3,3,0,0,0-3,3V5H2A1,1,0,0,0,2,7H5V26a5.006,5.006,0,0,0,5,5H22a5.006,5.006,0,0,0,5-5V7h3a1,1,0,0,0,0-2ZM12,4a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1V5H12ZM25,26a3,3,0,0,1-3,3H10a3,3,0,0,1-3-3V7H25Z" />
											</svg>
											Delete</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<ng-template #noWindowPermit2Image>
						<div class="col-md-4">
							<div class="vehtype_extint">
								<div>
									<p>
										Secretary of State
										<!-- <span *ngIf="
												affiliateType !=
													'fleet_operator';
												else nonFleetOperator
											" class="asterisk">*</span> -->
										<ng-template #nonFleetOperator>
											<span class="optionalTextStyle">(optional)</span>
										</ng-template>
									</p>
									<div class="emptyImage">
										<img src="../../../../assets/images/empty-image.png" tabindex="-1" />
									</div>
									<ul class="mt-3">
										<li>
											<button class="adduploaded" tabindex="1">
												<input type="file" (change)="
														vehicleOfficialImagesChange(
															$event,
															'windowPermit2',
															windowPermit2Id
														)
													" tabindex="-1" accept="image/jpeg, image/png" />
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
													<path fill="none" d="M0 0h24v24H0V0z" />
													<circle cx="12" cy="12" r="3" />
													<path
														d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
												</svg>
												Add
											</button>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</ng-template>
				</div>

				<div class="row" *ngIf="affiliateType != 'fleet_operator'">
					<div class="col-md-4" *ngIf="usdotPermitImage; else noUsdotPermitImage">
						<div class="vehtype_extint">
							<div>
								<p>
									USDOT Permit
									<!-- <span *ngIf="
											affiliateType != 'fleet_operator';
											else nonFleetOperator
										" class="asterisk">*</span> -->
									<ng-template #nonFleetOperator>
										<span class="optionalTextStyle">(optional)</span>
									</ng-template>
								</p>
								<img src="{{ usdotPermitImage }}" (click)="showImageInModal(usdotPermitImage)"
									tabindex="-1" />
								<ul class="mt-3">
									<li>
										<a class="custom-arrow-clockwise-button"
											(click)="fetchImageBlob(usdotPermitImage,'usdotPermit',usdotPermitId)">
											<i class="bi bi-arrow-clockwise"></i>rotate</a>
									</li>
									<li>
										<button class="changeuploaded" tabindex="1">
											<input type="file" (change)="
													vehicleOfficialImagesChange(
														$event,
														'usdotPermit',
														usdotPermitId
													)
												" tabindex="-1" accept="image/jpeg, image/png" />
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
												<path fill="none" d="M0 0h24v24H0V0z" />
												<circle cx="12" cy="12" r="3" />
												<path
													d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
											</svg>
											Change
										</button>
									</li>
									<li>
										<a class="delete-btn" (click)="
												deleteImage(
													usdotPermitId,
													'usdotPermit'
												)
											" tabindex="1">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
												<path
													d="M30,5H22V4a3,3,0,0,0-3-3H13a3,3,0,0,0-3,3V5H2A1,1,0,0,0,2,7H5V26a5.006,5.006,0,0,0,5,5H22a5.006,5.006,0,0,0,5-5V7h3a1,1,0,0,0,0-2ZM12,4a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1V5H12ZM25,26a3,3,0,0,1-3,3H10a3,3,0,0,1-3-3V7H25Z" />
											</svg>
											Delete</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<ng-template #noUsdotPermitImage>
						<div class="col-md-4">
							<div class="vehtype_extint">
								<div>
									<p>
										USDOT Permit
										<!-- <span *ngIf="
												affiliateType !=
													'fleet_operator';
												else nonFleetOperator
											" class="asterisk">*</span> -->
										<ng-template #nonFleetOperator>
											<span class="optionalTextStyle">(optional)</span>
										</ng-template>
									</p>
									<div class="emptyImage">
										<img src="../../../../assets/images/empty-image.png" tabindex="-1" />
									</div>
									<ul class="mt-3">
										<li>
											<button class="adduploaded" tabindex="1">
												<input type="file" (change)="
														vehicleOfficialImagesChange(
															$event,
															'usdotPermit',
															usdotPermitId
														)
													" tabindex="-1" accept="image/jpeg, image/png" />
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
													<path fill="none" d="M0 0h24v24H0V0z" />
													<circle cx="12" cy="12" r="3" />
													<path
														d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
												</svg>
												Add
											</button>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</ng-template>

					<div class="col-md-4" *ngIf="mcImage; else noMcImage">
						<div class="vehtype_extint">
							<div>
								<p>
									Vehicle Inspection Certificate
									<!-- <span *ngIf="
											affiliateType != 'fleet_operator';
											else nonFleetOperator
										" class="asterisk">*</span> -->
									<ng-template #nonFleetOperator>
										<span class="optionalTextStyle">(optional)</span>
									</ng-template>
								</p>
								<img src="{{ mcImage }}" (click)="showImageInModal(mcImage)" tabindex="-1" />
								<ul class="mt-3">
									<li>
										<a class="custom-arrow-clockwise-button"
											(click)="fetchImageBlob(mcImage,'mc',mcId)">
											<i class="bi bi-arrow-clockwise"></i>rotate</a>
									</li>
									<li>
										<button class="changeuploaded" tabindex="1">
											<input type="file" (change)="
													vehicleOfficialImagesChange(
														$event,
														'mc',
														mcId
													)
												" tabindex="-1" accept="image/jpeg, image/png" />
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
												<path fill="none" d="M0 0h24v24H0V0z" />
												<circle cx="12" cy="12" r="3" />
												<path
													d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
											</svg>
											Change
										</button>
									</li>
									<li>
										<a class="delete-btn" (click)="deleteImage(mcId, 'mc')" tabindex="1">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
												<path
													d="M30,5H22V4a3,3,0,0,0-3-3H13a3,3,0,0,0-3,3V5H2A1,1,0,0,0,2,7H5V26a5.006,5.006,0,0,0,5,5H22a5.006,5.006,0,0,0,5-5V7h3a1,1,0,0,0,0-2ZM12,4a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1V5H12ZM25,26a3,3,0,0,1-3,3H10a3,3,0,0,1-3-3V7H25Z" />
											</svg>
											Delete</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<ng-template #noMcImage>
						<div class="col-md-4">
							<div class="vehtype_extint">
								<div>
									<p>
										Vehicle Inspection Certificate
										<!-- <span *ngIf="
												affiliateType !=
													'fleet_operator';
												else nonFleetOperator
											" class="asterisk">*</span> -->
										<ng-template #nonFleetOperator>
											<span class="optionalTextStyle">(optional)</span>
										</ng-template>
									</p>
									<div class="emptyImage">
										<img src="../../../../assets/images/empty-image.png" tabindex="-1" />
									</div>
									<ul class="mt-3">
										<li>
											<button class="adduploaded" tabindex="1">
												<input type="file" (change)="
														vehicleOfficialImagesChange(
															$event,
															'mc',
															mcId
														)
													" tabindex="-1" accept="image/jpeg, image/png" />
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
													<path fill="none" d="M0 0h24v24H0V0z" />
													<circle cx="12" cy="12" r="3" />
													<path
														d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
												</svg>
												Add
											</button>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</ng-template>
				</div>
			</div>
		</div>
	</section>
	<div class="container">
		<div class="col-md-12">
			<div class="billing_inforamtion ">
				<ul class="d-flex justify-content-center" style="gap:30px;">
					<li>
						<button class="custom-filled-back-button" type="button" (click)="backButton()"
							data-toggle="tooltip" data-placement="top" title="Clicking back button deletes all fields."
							tabindex="1">BACK</button>
					</li>
		
					<li><button class="custom-filled-reset-button" (click)="resetForm()" tabindex="1">RESET</button>
					</li>
					<li><button class="custom-filled-button" (click)="submitForm()" [disabled]="disableSubmitButton"
							tabindex="1">SAVE</button></li>
					<!-- <li>
						</li> -->
				</ul>
			</div>
		</div>
	</div>

</form>

<!-- Image modal -->
<app-image-modal (closeTab)="closeButton()" [modalImage]="modalImage"></app-image-modal>

<!-- Photo Instructions modal -->
<app-photo-instructions></app-photo-instructions>