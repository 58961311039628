// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	environmentName: 'Development',
	// serverUrl: "http://10.20.20.63:8000/api/",
	accessKeyId: "AKIAZTOUHGGRSIV2GIVY",
	secretAccessKey: "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",  
	// recaptchaKey:"6Lcf2MYqAAAAAMPwdv97BZ_LBwX-1e9y2NcFsoIn",
	serverUrl: "https://1800limoapi.infodevbox.com/api/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
